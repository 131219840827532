import { document, window } from '../globals';
import { isObject } from '../typeguards';
import { localStorage } from '../util/storage';

interface ChatbotState {
    state: {
        minimized: boolean;
        visible: boolean;
    };
}

type SetConversationVariableMetadata = {
    key: string;
    value: string;
};

export const isSetConversationVariableMetadata = (
    setConversationVariableMetadata: unknown,
): setConversationVariableMetadata is SetConversationVariableMetadata => (
    isObject(setConversationVariableMetadata) && (
        typeof setConversationVariableMetadata?.key === 'string'
        && typeof setConversationVariableMetadata?.value === 'string'
    )
);

export default class GiftyChat {
    private static SKIPPED_INTERACTION_MESSAGE = 'Notice: System skipped interaction';
    private static hasBeenLoaded = false;

    private static loading = false;

    private static readonly WIDGET_URL = 'https://webchat.digitalcx.com/webchat.js';

    private static readonly BUTTON_SELECTOR = '.cm-button-js';

    private static readonly DATA_CX_ID_SELECTOR = 'data-cxID';
    private static readonly DATA_CX_WEB_STORE_CONTEXT_VARIABLE_SELECTOR = 'data-cxWebStoreContextVariable';
    private static readonly DATA_CX_WEB_STORE_CONVERSATION_VARIABLE_SELECTOR = 'data-cxWebStoreConversationVariable';
    private static readonly DATA_CX_ALGOLIA_INDEX = 'data-cxAlgoliaIndex';
    private static readonly DATA_CX_BASE_URL = 'data-cxBaseUrl';
    private static readonly DATA_CX_PAGE_ID = 'data-cxPageId';
    private static readonly DATA_CX_CUSTOMER_SERVICE_EMAIL = 'data-cxCustomerServiceEmail';
    private static readonly DATA_CX_CUSTOMER_SERVICE_PHONE = 'data-cxCustomerServicePhone';
    private static readonly DATA_CX_DEFAULT_SHIPPING_COUNTRY = 'data-cxDefaultShippingCountry';
    private static readonly DATA_CX_CURRENCY = 'data-cxCurrency';

    private static getCxId(button: HTMLButtonElement): string | null {
        return button.getAttribute(this.DATA_CX_ID_SELECTOR);
    }

    private static getCxVariable(button: HTMLButtonElement, variable: string): string {
        return button.getAttribute(variable) ?? '';
    }

    private static getButtonElement(): HTMLButtonElement | null | undefined {
        return document?.querySelector(this.BUTTON_SELECTOR);
    }

    public static bindListeners(): void {
        const button = this.getButtonElement();
        if (!(button instanceof HTMLButtonElement)) {
            return;
        }

        button.addEventListener('click', () => {
            this.loadScript('StartChat').then((cxId) => {
                this.toggleChat(cxId);
            }).catch(() => null);
        });
    }

    public static sendEvent(cxId: string, event: string) {
        const chatBotInstance = window?.cmwc?.get(cxId);
        if (chatBotInstance && isObject(chatBotInstance)) {
            if (event === 'StartChat') {
                // To kick-start, so to speak, the language in Gifty, we send dummy data and start the chat using an article trigger
                chatBotInstance.caic.addMetadataToPayload({ foo: 'bar' });
                chatBotInstance.caic.askHidden(event);
                return;
            }

            chatBotInstance.sendEvent(event);
        }
    }

    private static openChat(cxId: string) {
        const chatBotInstance = window?.cmwc?.get(cxId);
        if (chatBotInstance && isObject(chatBotInstance)) {
            chatBotInstance.open();
        }
    }

    private static onAnswer(cxId: string) {
        const chatBotInstance = window?.cmwc?.get(cxId);
        if ((chatBotInstance && isObject(chatBotInstance))) {
            chatBotInstance.onAnswer((event) => {
                if (event.detail.chat.metadata.data?.outputAdditions?.setConversationVariable) {
                    const obj = JSON.parse(event.detail.chat.metadata.data.outputAdditions.setConversationVariable) as unknown;

                    if (isSetConversationVariableMetadata(obj)) {
                        chatBotInstance.addConversationVariables({ [obj.key]: obj.value });
                    }
                }

                if (event.detail.chat.metadata.data?.outputAdditions?.skipInteraction === 'true') {
                    chatBotInstance.caic.askHidden(this.SKIPPED_INTERACTION_MESSAGE);
                }
            });
        }
    }

    public static showChat(cxId: string) {
        const chatBotInstance = window?.cmwc?.get(cxId);
        if (chatBotInstance && isObject(chatBotInstance)) {
            chatBotInstance.show();
        }
    }

    public static hideChat(cxId: string) {
        const chatBotInstance = window?.cmwc?.get(cxId);
        if (chatBotInstance && isObject(chatBotInstance)) {
            chatBotInstance.hide();
        }
    }

    private static getState(cxId: string): ChatbotState | null {
        const appItem = localStorage.getItem(`${cxId}_${cxId}.app`);

        return appItem ? JSON.parse(appItem) as ChatbotState : null;
    }

    public static toggleChat(cxId: string) {
        const chatState = this.getState(cxId)?.state;

        if (!chatState) {
            return { open: false };
        }

        // Gifty doesn't properly set the state of 'visible' after closing
        // In which case we have to open the chat rather than show it
        if (chatState.visible && chatState.minimized) {
            this.openChat(cxId);

            return { open: true };
        }

        if (!chatState.visible) {
            this.showChat(cxId);

            return { open: true };
        }

        this.hideChat(cxId);
        return { open: false };
    }

    private static addInitialWebStoreContext(cxId: string): void {
        const chatBotInstance = window?.cmwc?.get(cxId);
        const button = this.getButtonElement();
        if (button && chatBotInstance && isObject(chatBotInstance)) {
            const webStoreContextVariable = this.getCxVariable(button, this.DATA_CX_WEB_STORE_CONTEXT_VARIABLE_SELECTOR);

            if (webStoreContextVariable) {
                chatBotInstance.addContext({ webStore: webStoreContextVariable });
            }
        }
    }

    private static addInitialWebStoreConversationVariable(cxId: string): void {
        const chatBotInstance = window?.cmwc?.get(cxId);
        const button = this.getButtonElement();
        if (button && chatBotInstance && isObject(chatBotInstance)) {
            chatBotInstance.addConversationVariables({
                algoliaIndex: this.getCxVariable(button, this.DATA_CX_ALGOLIA_INDEX),
                baseUrl: this.getCxVariable(button, this.DATA_CX_BASE_URL),
                currency: this.getCxVariable(button, this.DATA_CX_CURRENCY),
                customerServiceEmail: this.getCxVariable(button, this.DATA_CX_CUSTOMER_SERVICE_EMAIL),
                customerServicePhone: this.getCxVariable(button, this.DATA_CX_CUSTOMER_SERVICE_PHONE),
                defaultShippingCountry: this.getCxVariable(button, this.DATA_CX_DEFAULT_SHIPPING_COUNTRY),
                pageId: this.getCxVariable(button, this.DATA_CX_PAGE_ID),
                webStore: this.getCxVariable(button, this.DATA_CX_WEB_STORE_CONVERSATION_VARIABLE_SELECTOR),
            });
        }
    }

    private static addInitialPageTypeContextVariable(cxId: string): void {
        const chatBotInstance = window?.cmwc?.get(cxId);
        if (chatBotInstance && isObject(chatBotInstance)) {
            const pageType = window?.pageType.replaceAll(' ', '_');

            if (pageType) {
                chatBotInstance.addContext({ pageType });
            }
        }
    }

    public static loadScript(initialEvent?: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const button = this.getButtonElement();
            if (!(button instanceof HTMLButtonElement)) {
                return;
            }

            const cxId = this.getCxId(button);

            if (!cxId || !window || !document || this.loading) {
                reject();
                return;
            }

            if (this.hasBeenLoaded) {
                resolve(cxId);
                return;
            }

            this.toggleLoad(true);
            const scriptElement = document.createElement('script');
            scriptElement.type = 'module';
            scriptElement.src = this.WIDGET_URL;

            scriptElement.onload = () => {
                this.hasBeenLoaded = true;
                this.toggleLoad(false);
                window?.cmwc?.add(cxId, () => {
                    if (window?.siteMetadata?.language) {
                        window?.cmwc?.get(cxId)?.setLanguage(window.siteMetadata.language.toLowerCase());
                    }

                    this.addInitialWebStoreContext(cxId);
                    this.addInitialPageTypeContextVariable(cxId);
                    this.addInitialWebStoreConversationVariable(cxId);

                    if (initialEvent) {
                        this.sendEvent(cxId, initialEvent);
                    }
                    resolve(cxId);
                }).install();

                // If there is already a state in localStorage, the callbackFunction from .add() is not being called, so we open chat here instead
                if (this.getState(cxId)) {
                    resolve(cxId);
                }
            };

            this.onAnswer(cxId);
            document.body.appendChild(scriptElement);
        });
    }

    private static toggleLoad(state: boolean): void {
        this.loading = state;
    }
}
